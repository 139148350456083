#print-content-root {
  display: none;
}

@media print {
  body.print-content-mode {
    background-color: transparent;
  }

  body.print-content-mode > *:not(#print-content-root) {
    display: none;
  }

  #print-content-root {
    display: block;
  }
}

.dropzone {
  padding: 20px;
  border: 1px #F74F32 dashed;
  width: 100%;
  margin: auto;
  border-radius: 8px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.document-view__wrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  height: 100%;

  #proxy-renderer {
    overflow-y: auto;
  }

  .react-pdf__Page {
    margin-bottom: 10px;
    background-color: transparent !important;
  }
}

.chat-view__wrapper {
  height: calc(100vh - 320px);
  overflow-y: scroll;
  padding: 10px;
  margin-bottom: 10px;

  .chat-question {
    max-width: 90%;
    background-color: #fff;
    color: #F74F32;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    font-size: 14px;
  }

  .chat-answer {
    max-width: 90%;
    background-color: #F74F32;
    color: #fff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    position: relative;
    left: 10%;
    font-size: 14px;
  }
}

.answer-block-promts-list {
  margin: 0;
  padding: 0;
  margin-top: 10px;
  list-style: none;

  li {
    background-color: #fff;
    color: #212529;
    cursor: pointer;
    border: 1px solid #ced4da;
    padding: 15px;
    margin: 0;
    margin-bottom: 10px;
    border-radius: 8px;
    font-size: 14px;

    &.active:hover {
      background-color: #F74F32;
      border: 1px solid #F74F32;
      color: #fff;
    }

    &.disabled {
      background-color: #f4f5f7;
      color: #adb5bd;
      cursor: auto;
    }
  }
}