.wrapper {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .icon {
    color: #F74F32;
  }

  .title {
    font-size: 48px;
    margin: 0;
  }

  .text {
    font-size: 32px;
    margin: 0;
    text-align: center;
  }

  .subtext {
    margin-top: 16;
    font-size: 24px;
    margin: 0;
    text-align: center;
  }
}
