.activeRow {
  color: #f74f32 !important;
}

.text {
  font-size: 18px;
  align-items: center;
  margin: 0 0 15px 0;
}

.subtext {
  font-size: 14px;
  align-items: center;
  margin: 0;
}

.sortedRow {
  cursor: pointer;

  .rowName {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    .orderIcon {
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }
}
