.wrap {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  max-height: calc(100vh - 180px);
  overflow-y: scroll;
  justify-content: space-between;

  .infoWrap {
    padding: 16px;
    border-radius: 16px;
    width: 100%;
    background-color: #f6d7d0;

    .title {
      font-weight: bold;
      margin: 0;
      padding: 0 0 16px;
      font-size: 24px;
    } 

    .stat {
      width: 100%;
      background-color: #fff;
      color: #000;
      border-radius: 16px;
      padding: 16px;

      .label {
        margin: 0;
        text-align: center;
        width: 100%;
        font-weight: bold;
        font-size: 14px;
        padding: 0;
        text-transform: capitalize;
      }

      .value {
        margin: 8px 0 0;
        padding: 16px;
        background-color: #fff;
        border-radius: 8px;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        width: 100%;
      }
    }
  }

  .statsWrap {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 16px;

    .stat {
      width: 100%;
      background-color: #fff;
      color: #000;
      border-radius: 16px;
      max-width: 24%;
      padding: 16px;
      align-self: stretch;

      @media screen and (max-width: 1440px) {
        max-width: 100%;
      }

      .label {
        margin: 0;
        text-align: center;
        width: 100%;
        font-weight: bold;
        font-size: 14px;
        padding: 0;
        text-transform: capitalize;
      }

      .value {
        margin: 0;
        padding: 0;
        background-color: #fff;
        border-radius: 8px;
        width: 100%;
        height: calc(100% - 12px);
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          font-size: 14px;
          margin: 0;
          font-weight: normal;
          width: 100%;
          text-align: center;
          text-wrap: wrap;
          overflow-wrap: break-word;
        }
      }
    }
  }

  .keysWrap {
    margin-top: 16x;
    display: flex;
    gap: 8px;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;

    .title {
      font-weight: bold;
      font-size: 24px;
      margin: 0;
      display: flex;
      align-items: center;
    }

    .key {
      width: 100%;
      color: #000;
      border-radius: 16px;
      padding: 16px;

      .label {
        margin: 0;
        width: 100%;
        font-weight: bold;
        font-size: 20px;
        padding: 0;
        text-transform: capitalize;
        display: flex;
        align-items: center;

        span {
          height: 8px;
          width: 8px;
          background-color: #F74F32;
          margin-right: 8px;
          border-radius: 8px;
        }
      }

      .value {
        margin: 8px 0 0;
        padding: 16px;
        border-radius: 8px;
        font-size: 16px;
        width: 100%;
      }
    }
  }
}
