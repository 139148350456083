.html-document-view__wrapper {
  display: flex;
  justify-content: center;

  & > div {
    max-width: 780px;
    width: 100%;
  }

  .html-summary-answer-block {
    margin: 5px 0 10px;
    background-color: #fff;
    width: 80%;
    margin-left: 20%;
    padding: 15px;
    border-radius: 8px;
  }

  .html-block {
    transition: all 0.2s;
    border-radius: 2px;
    font-size: 10px;
    word-break: break-word;
    // min-height: 200px;

    ol {
      li {
        text-indent: 0 !important;

        span {
          // text-indent: 0 !important;
        }
      }
    }

    &.hovered {
      cursor: pointer;

      &.active {
        background-color: #F74F323d;

        span {
          background-color: transparent !important;
        }
      }

      &:hover {
        background-color: #F74F323d;

        span {
          background-color: transparent !important;
        }
      }
    }

    //   p {
    //     margin: 0;
    //   }
  }

  .answer-block {
    background: #fff;
    transition: all 0.2s;
    border-radius: 8px;
    padding: 15px;
    border: 2px #fff solid;
    border: 2px #F74F32 solid;
    margin: 5px 0;
    font-size: 14px;
    word-break: break-word;

    &.hovered {
      cursor: pointer;

      &.active {
        background: #F74F323d;
      }

      &:hover {
        background: #F74F323d;
      }
    }

    .answer-block-promt {
      margin-top: 10px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
