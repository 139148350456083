.wrap {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
  justify-content: space-between;

  .statsWrap {
    width: 80%;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    .stat {
      width: 100%;
      background-color: #f6d7d0;
      border-radius: 16px;
      padding: 16px;

      .label {
        margin: 0;
        text-align: center;
        width: 100%;
        font-weight: bold;
        font-size: 14px;
        padding: 0;
        text-transform: capitalize;
      }

      .value {
        margin: 8px 0 0;
        padding: 16px;
        background-color: #fff;
        border-radius: 8px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        width: 100%;
      }
    }
  }

  .filter {
    display: flex;
    flex-direction: column;
    min-width: 250px;
    gap: 8px;
    width: 20%;
  }
}
