.activeRow {
  color: #f74f32 !important;
}

.rowName {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  .orderIcon {
    &.rotate {
      transform: rotate(180deg);
    }
  }
}